import React, { memo } from "react"
import { FormControl, FormLabel, Textarea } from "@chakra-ui/react"

type Props = {
  field: {
    _key: string
    _type: string
    label: string
    name: string
    placeholder?: string
    type: "text" | "email" | "tel"
    required?: boolean
  }
}

export const FormTextarea: React.FC<Props> = memo(({ field }) => (
  <FormControl key={field._key} id={field?.name} isRequired={field?.required} variant="floating">
    <Textarea name={field?.name} />
    {field?.label && <FormLabel>{field?.label}</FormLabel>}
  </FormControl>
))
